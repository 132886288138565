import './App.css';

function App() {
  return (
    <div className="App">
      <div className='AppContainer'>
        <h1>Coming Soon...</h1>
        <h2>Site is under maintenance. Thanks!</h2>
      </div>
    </div>
  );
}

export default App;
